import React from 'react';
import styles from "./HHSReport.module.less";

const InnerageAffectingHabits = (props: {
  data: any,
}) => {
  const { data } = props;

  return (
    <div className={`${styles.habitsAffect} ${styles.box} ${styles.boxGreen}`}>
      <p className={styles.boxTitle}>
        How your top and bottom habits are potentially affecting your InnerAge
      </p>
      {data && (
        <>
            {data.texts.top_habit_w_InnerAge_string.value && data.texts.top_habit_w_InnerAge_biomarkers.value && (
            <div className={styles.habitsAffectInfoWrap}>
              <div className={styles.iconPlus}>+</div>
              <div className={styles.habitsAffectInfo} id="habitsAffect-good">
                <p className={styles.habitsAffectInfoTitle}>
                  {data.texts.top_habit_w_InnerAge_string.value}
                  {' '}
                  <span>
                    may positively affect:
                  </span>
                </p>
                {data.texts.top_habit_w_InnerAge_biomarkers.value && (
                  <p className={styles.habitsAffectInfoText}>
                    {data.texts.top_habit_w_InnerAge_biomarkers.value.join(', ')}
                  </p>
                )}
              </div>
            </div>
            )}

            {data.texts.lowest_habit_w_InnerAge_string.value && data.texts.lowest_habit_w_InnerAge_biomarkers.value && (
            <div className={styles.habitsAffectInfoWrap}>
              <div className={styles.iconMinus}>&ndash;</div>
              <div className={styles.habitsAffectInfo} id="habitsAffect-bad">
                <p className={styles.habitsAffectInfoTitle}>
                  {data.texts.lowest_habit_w_InnerAge_string.value}
                  {' '}
                  <span>
                    may negatively impact:
                  </span>
                </p>
                {data.texts.lowest_habit_w_InnerAge_biomarkers.value && (
                <p className={styles.habitsAffectInfoText}>
                  {data.texts.lowest_habit_w_InnerAge_biomarkers.value.join(', ')}
                </p>
                )}
              </div>
            </div>
            )}
        </>
      )}
    </div>
  );
};

export default InnerageAffectingHabits;
